import Header from "./components/Header";
import AnimatedRoutes from "./components/AnimatedRoutes";

function App() {
  const personalDetails = {
    name: "Aluko IT Solutions",
    location: "Loughborough, UK",
    email: "michael-yeates@outlook.com",
    availability: "Open for work",
    brand:
      "Our unique approach of making use of the right tools and technologies, coupled with our mix of expertise has positioned us to effectively support various businesses over the years",
  };

  return (
    <>
      <Header />
      <AnimatedRoutes personalDetails={personalDetails} />
    </>
  );
}

export default App;
